@tailwind base;
@tailwind components;
@tailwind utilities;

  
  /* Hide scrollbar for Webkit browsers */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}